/**
 * Base class for app custom error classes.
 */
export class AppError extends Error {
  /**
   * App error class constructor.
   *
   * @param {Error} originalError The original error object.
   */
  constructor(errorKey, errorMessage, originalError, statusCode) {
    super(originalError);
    this.errorMessage = errorMessage;
    this.errorKey = errorKey;
    this.statusCode = statusCode;
  }
}
