import axios from "axios";
import {
  AppError,
  BadRequestError,
  ConflictError,
  NotFoundError,
  UnauthorizedError,
} from "errors";

import { StatusCode } from "constants/";
import { TokenUtils } from "utils";
import appConfig from "../config";

const userTimeZone = new Date().getTimezoneOffset() / -60;
/**
 * Handle expected HTTP errors and throw app custom errors.
 *
 * @param {Error} error Axios response error object.
 */
export const handleExpectedError = (error) => {
  const { response } = error;
  const { message: ExpectedErrorMessage } = response?.data || {};
  switch (response.status) {
    case StatusCode.BAD_REQUEST:
      return Promise.reject(
        new BadRequestError(
          ExpectedErrorMessage &&
          ExpectedErrorMessage ===
            "FAILED no account associated with this email"
            ? "No account associated with this email"
            : ExpectedErrorMessage.key,
          ExpectedErrorMessage &&
          ExpectedErrorMessage ===
            "FAILED no account associated with this email"
            ? "No account associated with this email"
            : ExpectedErrorMessage
        )
      );

    case StatusCode.UNAUTHORIZED:
      return Promise.reject(
        new UnauthorizedError(
          ExpectedErrorMessage ? "Password is incorrect" : null,
          ExpectedErrorMessage ? "Password is incorrect" : "expired-token"
        )
      );

    case StatusCode.CONFLICT: {
      let message = null;
      if (ExpectedErrorMessage === "conflictPhoneNumber") {
        message = "businessPhoneNumberUsed";
      } else if (
        ExpectedErrorMessage.includes("Conflict") ||
        ExpectedErrorMessage.includes("conflictEmail")
      ) {
        message = "emailUsed";
      }
      return Promise.reject(
        new ConflictError(
          message,
          ExpectedErrorMessage ? message : "expired-token",
          message,
          response?.status
        )
      );
    }

    case StatusCode.NOT_FOUND:
      return Promise.reject(
        new NotFoundError(
          ExpectedErrorMessage ? ExpectedErrorMessage.key : null,
          ExpectedErrorMessage ? ExpectedErrorMessage : null
        )
      );

    default:
      return Promise.reject(
        new AppError(
          ExpectedErrorMessage ? ExpectedErrorMessage.key : null,
          ExpectedErrorMessage ? ExpectedErrorMessage : null
        )
      );
  }
};

/**
 * Handle unexpected HTTP errors.
 */
const handleUnexpectedError = (error) => Promise.reject(error);

/**
 * Axios HTTP unexpected errors.
 */
const responseErrorHandler = (error) => {
  const { response } = error;
  if (
    response &&
    response.status >= StatusCode.BAD_REQUEST &&
    response.status < StatusCode.INTERNAL_SERVER_ERROR
  ) {
    return handleExpectedError(error);
  }
  return handleUnexpectedError(error);
};

axios.interceptors.response.use(
  async (response) => {
    if (!response.data.data) return response;
    const { token, refreshToken } = response.data.data;
    if (token) {
      TokenUtils.set(token);
      TokenUtils.set(refreshToken, false);
    }
    return response;
  },
  (error) => responseErrorHandler(error)
);

axios.interceptors.request.use(
  async (config) => {
    const { token, refreshToken } = TokenUtils.get();
    config.headers.UserTimeZone = userTimeZone;
    if (
      !token ||
      config.url === `${appConfig?.apiBaseUrl}user-mgmt/refreshToken`
    ) {
      return config;
    }

    // Check if access token is expired
    if (TokenUtils.isTokenExpired()) {
      // Check if refresh token is expired
      if (TokenUtils.isTokenExpired(false)) {
        return Promise.reject({ errorMessage: "expired-token" });
      }

      try {
        const response = await axios.post(
          `${appConfig?.apiBaseUrl}user-mgmt/refreshToken`,
          {},
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        config.headers.Authorization = `Bearer ${response.data.data.token}`;
      } catch (error) {
        console.log(error);
        return Promise.reject({ errorMessage: "expired-token" });
      }
    } else {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default {
  /**
   * Http delete method
   */
  delete: axios.delete,

  /**
   * Http get method
   */
  get: axios.get,

  /**
   * Http patch method
   */
  patch: axios.patch,

  /**
   * Http post method
   */
  post: axios.post,

  /**
   * Http put method
   */
  put: axios.put,
};
