import { atom } from "jotai";
import { SenderService } from "services";

const senderService = new SenderService();
const userAtom = atom({
  address: "",
  businessEmail: "",
  businessName: "",
  businessNationalNumber: "",
  businessPhoneNumber: "",
  businessType: "",
  preferredLanguage: "",
  senderId: "",
  website: "",
});

export const userInfoAtom = atom(
  (get) => get(userAtom),
  async (get, set, newData) => {
    let data;

    // get user id from local storage and fetch user data
    if (!newData) {
      try {
        const { data: responseData } = await senderService.getUserInfo();

        delete responseData.files;
        delete responseData.openHours;
        delete responseData.senderContacts;

        data = responseData;
      } catch (error) {
        data = get(userAtom);
      }
    } else {
      // data from the application (profile page)
      data = newData;
    }

    set(userAtom, data);
  }
);
