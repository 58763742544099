export const CONTACTS_COLUMNS_NAMES = (t, isReversed) => {
  const columns = [
    {
      id: 0,
      name: t("name"),
    },
    {
      id: 1,
      name: t("occupation"),
    },
    {
      id: 2,
      name: t("mobileNumber"),
    },
    {
      id: 3,
      name: t("status"),
    },
    {
      id: 4,
      name: t("action"),
    },
  ];

  return isReversed ? columns.reverse() : columns;
};

export const CONTACT_INPUT_FIELDS = (t) => [
  {
    id: 0,
    label: "name",
    placeholder: t("eg: Ahmad"),
    type: "text",
  },
  {
    id: 1,
    label: "occupation",
    placeholder: t("eg: Supervisor"),
    type: "text",
  },
  {
    id: 2,
    label: "mobileNumber",
    placeholder: t("7777777777"),
    type: "tel",
  },
];

export const CONTACT_ACTIVE_OPTIONS = (t) => [
  {
    label: t("allOptions"),
    value: null,
  },
  {
    label: t("active"),
    value: true,
  },
  {
    label: t("inactive"),
    value: false,
  },
];
