export const ADDRESS_BOOK_COLUMNS_NAMES = (t, isReversed) => {
    const columns = [
      {
        id: 0,
        name: t("name"),
      },
      {
        id: 1,
        name: t("phoneNo"),
      },
 
      {
        id: 2,
        name: t("address"),
      },
    ];
  
    return isReversed ? columns.reverse() : columns;
  };