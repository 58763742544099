import { languageAtom } from "atoms";
import { useAtom } from "jotai";
import i18n from "../i18n";

import { ARABIC_LANG } from "../constants";
import { useEffectOnce } from "./use-effect-once";

export const useTranslationHook = () => {
  const [language, setLanguage] = useAtom(languageAtom);

  const changeLanguageStyles = (lang) => {
    let fontFamily =
      lang === ARABIC_LANG ? '"Almarai", sans-serif' : '"Poppins", sans-serif';
    let globalAlign = lang === ARABIC_LANG ? "flex-end" : "flex-start";
    let textAlign = lang === ARABIC_LANG ? "end" : "start";
    let globalFlexDirection = lang === ARABIC_LANG ? "row-reverse" : "row";
    let globalPadding =
      lang === ARABIC_LANG ? "0rem 0rem 0.25rem" : "0.25rem 0rem 0rem";
    let globalGridDirection = lang === "ar" ? "rtl" : "ltr";

    return {
      "--checkbox-padding": globalPadding,
      "--font-family": fontFamily,
      "--global-align": globalAlign,
      "--global-flex-direction": globalFlexDirection,
      "--global-grid-direction": globalGridDirection,
      "--global-text-align": textAlign,
    };
  };

  const applyLanguageSettings = (lang) => {
    const styles = changeLanguageStyles(lang);
    const root = document.documentElement;
    Object.entries(styles).forEach(([key, value]) => {
      root.style.setProperty(key, value);
    });
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage !== lang) {
      localStorage.removeItem("lang");
      localStorage.setItem("lang", lang);
    }
    applyLanguageSettings(lang);
  };

  useEffectOnce(() => applyLanguageSettings(language));

  return { handleLanguageChange, language };
};
