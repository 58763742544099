export default {
  businessDetails: "Business Details",
  businessDetailsSubHeader:
    "This is your business details once profile created.",
  businessDocuments: "Business Documents",
  businessDocumentsSubHeader: "This is your business uploaded documents.",
  businessHours: "Business Hours",
  businessHoursSubHeader: "This is your business working hours.",
  businessProfile: "Business Profile",
  businessProfileSubHeader: "Update your business details here.",
  closes: "Closes",
  enterValidTime: "Please enter a valid time",
  enterValidURL: "Please enter a valid url",
  inputURL: "Input URL",
  nationalNumber: "Business National Number",
  opens: "Opens",
  saveDataFail: "Error saving changes, please try again",
  saveDataSuccess: "Changes saved successfully",
  typeHour: "Type hour",
  validationError: "Some fields are empty or invalid",
  websiteURL: "Website URL",
  websites: "Websites",
  websitesSubHeader: "This is your business website and social media accounts.",
};
