import { useSetAtom } from "jotai";
import {
  autoFillMobileAtom,
  registeredUserAtom,
  userFilesAtom,
  userStatusAtom,
} from "atoms";
import { TokenUtils } from "utils";

export const useLogout = () => {
  const setUserStatus = useSetAtom(userStatusAtom);
  const setAutoFillMobile = useSetAtom(autoFillMobileAtom);
  const setRegisteredUserInfo = useSetAtom(registeredUserAtom);
  const setUserFiles = useSetAtom(userFilesAtom);

  const logOut = () => {
    TokenUtils.remove();
    localStorage.removeItem("userInfo");
    setUserStatus(null);
    setAutoFillMobile(false);
    setRegisteredUserInfo({});
    setUserFiles({});
  };

  return logOut;
};
