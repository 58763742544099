export const LOCATION_INPUT_FIELDS = (t) => [
  {
    id: 0,
    label: "locationTitle",
    placeholder: t("location"),
    type: "text",
  },
  {
    id: 1,
    label: "businessContactPerson",
    placeholder: t("chooseContact"),
    type: "multi-select",
  },
  {
    gridColumns: 2,
    id: 2,
    label: "city",
    placeholder: t("city"),
    type: "location",
  },
  {
    gridColumns: 2,
    id: 3,
    label: "area",
    placeholder: t("area"),
    type: "location",
  },
  {
    gridColumns: 2,
    id: 4,
    label: "streetName",
    placeholder: t("streetName"),
    type: "text",
  },
  {
    gridColumns: 2,
    id: 5,
    label: "buildingNumber",
    placeholder: t("typeNumber"),
    type: "text",
  },
  {
    gridColumns: 2,
    id: 6,
    label: "floorNumber",
    placeholder: t("typeNumber"),
    type: "text",
  },
  {
    gridColumns: 2,
    id: 7,
    label: "officeNumber",
    placeholder: t("typeNumber"),
    type: "text",
  },
];
