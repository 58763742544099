export default {
  agreementText: "أوافق على",
  alreadyHaveAnAccount: " لديك حساب؟",
  and: "و",
  businessName: "اسم العمل",
  businessNameIsRequired: "اسم العمل مطلوب",
  businessPhoneNumber: "رقم هاتف العمل",
  businessPhoneNumberIsRequired: "رقم هاتف العمل مطلوب",
  businessPhoneNumberUsed: "لقد تم استخدام رقم الهاتف من قبل",
  businessType: "نوع العمل",
  businessTypeIsRequired: "نوع العمل مطلوب",
  businessTypePlaceholder: "اختر نوع العمل",
  codeReceiveCheck: "لم تتلقَ الرمز؟",
  confirmPassword: "تأكيد كلمة المرور",
  confirmPasswordIsRequired: "حقل تأكيد كلمة المرور مطلوب",
  contactPersonName: "اسم الشخص المسؤول",
  contactPhoneNumber: "رقم هاتف الشخص المسؤول",
  contactPhoneNumberIsRequired: "رقم الهاتف مطلوب",
  continue: "التالي",
  eCommercePlatforms: "منصات التجارة الإلكترونية",
  email: "البريد الإلكتروني",
  emailUsed: "الحساب مستخدم بالفعل",
  enterCodeMessage: "أدخل الرمز من البريد الإلكتروني التي أرسلناها إلى",
  enterValidEmailAddress: "الرجاء إدخال بريد إالكتروني صحيح",
  enterValidPhoneNumber: "يجب إدخال رقم هاتف فعال",
  healthcarePharmaceutical: "شركات الرعاية الصحية والأدوية",
  login: "تسجيل الدخول",
  logisticsTransportation: "شركات اللوجستيات والنقل",
  manufacturers: "الشركات المصنعة",
  mustAcceptTermsAndPolicies: "يجب الموافقة على الشروط و الأحكام",
  nameMinError: "يجب أن يحتوي الاسم على أكثر من 3 أحرف",
  or: "أو",
  password: "كلمة المرور",
  passwordError8min: "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل",
  passwordErrorNoNumber: "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
  passwordErrorNoUppercase: "يجب أن تحتوي كلمة المرور على حرف كبير على الأقل",
  passwordNoSpecialChar: "يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
  passwordsMustMatch: "يجب تطابق كلمات المرور",
  personNameIsRequired: "اسم الشخص مطلوب",
  privacyPolice: "سياسة الخصوصية للمنصة",
  register: "تسجيل",
  registerFirstStep: ".الخطوة 1: البريد الإلكتروني",
  registerHeader: "سجل عملك",
  registerSecondStep: "الخطوة ٢: معلومات العمل.",
  registerSubHeader: "أنشئ حسابك باستخدام",
  resend: "إعادة الإرسال",
  retailers: "تجار التجزئة",
  serviceBased: "الأعمال القائمة على الخدمة",
  termOfService: "شروط الخدمة",
  verification: "التحقق بخطوتين",
  verify: "تحقق",
  wholesalersDistributors: "تجار الجملة والموزعين",
};
