import {
  dateValueAtom,
  isExpeditedAtom,
  orderStatusAtom,
  searchQueryAtom,
} from "atoms";
import { useSetAtom } from "jotai";

import { useFilterQueryParams } from "./index";

export const useResetFilters = () => {
  const { updateFilterParam } = useFilterQueryParams();

  const setSearchQuery = useSetAtom(searchQueryAtom);
  const setOrderStatus = useSetAtom(orderStatusAtom);
  const setIsExpedited = useSetAtom(isExpeditedAtom);
  const setDateValue = useSetAtom(dateValueAtom);

  const resetFilters = () => {
    updateFilterParam("page", 1);
    updateFilterParam("pageSize", 10);
    setSearchQuery("");
    setOrderStatus(0);
    setIsExpedited(null);
    setDateValue(undefined);
  };

  return resetFilters;
};
