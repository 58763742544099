export default {
  "No account associated with this email":
    "No account associated with this email",
  "Password is incorrect": "Email or Password is incorrect",
  doNotHaveAnAccount: "Don’t have an account?",
  emailIsRequired: "Email is required",
  loginAfterSuccessfullyRegister:
    "You can now login and complete your profile info",
  loginEmail: "Business Email",
  loginEmailPlaceholder: "Business email ",
  loginSubHeader: "Login to your account using",
  loginWelcomingMessage: "Welcome Back!",
  passwordIsRequired: "Password is required",
  registerNow: "Register Now",
  successfullyRegistered: "Your business account is created successfully!",
};
