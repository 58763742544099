export default {
  "No account associated with this email":
    "لا يوجد حساب مرتبط بهذا البريد الإلكتروني",
  "Password is incorrect": "البريد الإلكتروني أو كلمة المرور خاطئة",
  doNotHaveAnAccount: "ليس لديك حساب؟",
  emailIsRequired: "يجب إدخال البريد الالكتروني",
  loginAfterSuccessfullyRegister:
    "يمكنك الآن تسجيل الدخول وإكمال معلومات ملفك الشخصي",
  loginEmail: "البريد الإلكتروني للعمل",
  loginEmailPlaceholder: " البريد الإلكتروني للعمل",
  loginSubHeader: "سجّل الدخول إلى حسابك باستخدام",
  loginWelcomingMessage: "!مرحبا",
  passwordIsRequired: "يجب إدخال كلمة السر",
  registerNow: "سجّل الآن",
  successfullyRegistered: "!تم إنشاء حساب عملك بنجاح",
};
