export default {
  activate: "تفعيل",
  active: "مُفَعَّل",
  add: "إضافة",
  addContact: "إضافة جهة اتصال",
  address: "العنوان",
  allOptions: "الكل",
  businessPhone: "رقم الهاتف التجاري",
  cancel: "إلغاء",
  contactActivated: "تم تفعيل جهة الاتصال",
  contactAddedSuccessfully: "تمت إضافة جهة الاتصال بنجاح",
  contactDeactivated: "تم تعطيل جهة الاتصال",
  contactSearchPlaceholder: "البحث بالاسم، رقم الهاتف",
  contactUpdatedSuccessfully: "تم تحديث جهة الاتصال بنجاح",
  deactivate: "تعطيل",
  deleteContactHeader: "هل أنت متأكد أنك تريد إلغاء تفعيل جهة الاتصال؟",
  deleteContactSubHeader: "يمكنك إعادة تفعيلها متى شئت",
  editContact: "تعديل جهة الاتصال",
  inactive: "غَيْر مُفَعَّل",
  mobileNumber: "رقم الجوال للاتصال",
  name: "الاسم",
  nameIsRequired: "الاسم مطلوب",
  newContact: "جهة اتصال جديدة",
  secondPhone: "رقم الهاتف الثاني",
};
