export default {
  addLocation: "Add Location",
  area: "Area",
  areaIsRequired: "Area is required",
  buildingNumber: "Building Number",
  buildingNumberIsRequired: "Building number is required",
  businessContactPerson: "Business Contact Person",
  chooseContact: "Choose Contact",
  city: "City",
  cityIsRequired: "City is required",
  deleteLocationSecondWarning: "You will not be able to recover afterwards.",
  deleteLocationWarning: "Are you sure you want to delete Location?",
  floorNumber: "Floor Number",
  floorNumberIsRequired: "Floor number is required",
  location: "Location",
  locationCreatedSuccessfully: "Location Created Successfully",
  locationDeletedSuccessfully: "Location Deleted Successfully",
  locationIsRequired: "Location is required",
  locationTitle: "Location Title",
  locationTitleIsRequired: "Location title is required",
  locationUpdatedSuccessfully: "Location Updated Successfully",
  myLocations: "My Locations",
  myLocationsSubTitle:
    "Click on the map to set you location and then fill the other details",
  noLocationsMessageHeader: "No Saved Locations!",
  noLocationsSubMessageHeader:
    "Create a new location by clicking in “Add Location” button.",
  officeNumber: "Office Number",
  officeNumberIsRequired: "Office number is required",
  primaryContactNumber: "Primary Contact Number",
  primaryContactNumberIsRequired: "Primary contact number is required",
  primaryContactNumberPlaceholder: "Type contact number",
  search: "Search",
  streetName: "Street Name",
  streetNameIsRequired: "Street name is required",
  typeNumber: "Type Number",
  update: "Update",
};
