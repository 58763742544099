export const REGISTER_FORM_INPUTS_FIRST_STEP = [
  {
    id: 0,
    key: "email",
    placeholder: "username@user.com",
    type: "text",
  },
  {
    id: 1,
    key: "password",
    placeholder: "********",
    type: "password",
  },
  {
    id: 2,
    key: "confirmPassword",
    placeholder: "********",
    type: "password",
  },
];

export const REGISTER_FORM_INPUTS_SECOND_STEP = [
  {
    id: 3,
    key: "contactPersonName",
    placeholder: "contactPersonName",
    type: "text",
  },
  {
    id: 4,
    key: "businessName",
    placeholder: "businessName",
    type: "text",
  },
  {
    id: 5,
    key: "contactPhoneNumber",
    placeholder: "700000000",
    type: "tel",
  },
  {
    id: 6,
    key: "businessType",
    options: [
      {
        id: 1,
        label: "retailers",
        value: "1",
      },
      {
        id: 2,
        label: "manufacturers",
        value: "2",
      },
      {
        id: 3,
        label: "wholesalersDistributors",
        value: "3",
      },
      {
        id: 4,
        label: "eCommercePlatforms",
        value: "4",
      },
      {
        id: 5,
        label: "healthcarePharmaceutical",
        value: "5",
      },
      {
        id: 6,
        label: "logisticsTransportation",
        value: "6",
      },
      {
        id: 7,
        label: "serviceBased",
        value: "7",
      },
    ],
    placeholder: "businessTypePlaceholder",
  },
  {
    id: 11,
    key: "businessPhoneNumber",
    placeholder: "700000000",
    type: "tel",
  },
];
