/**
 * Convert bytes to other units
 *
 * @param {number} bytes     Num in bytes
 * @param {string} separator Separator between num and unit (default=space)
 *
 * @returns Converted num
 */
export const formatBytes = (bytes = 0, separator = " ") => {
  const units = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes}${separator}${units[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)}${separator}${units[i]}`;
};
