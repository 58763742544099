export default {
  accountSettings: "Account Settings",
  changePassword: "Change Password",
  chooseLanguage: "Choose Language",
  currentPassword: "Current Password",
  generalSettings: "General Settings",
  languagePreferences: "Language Preferences",
  newPassword: "New Password",
  settingsConfirmNewPassword: "Confirm new password",
  settingsNewPassword: "Type new password",
  settingsPassword: "Type password",
  updatePassword: "Update your password",
  userManagementSettings: "User Management",
};
