export const PASSWORD_FIELDS = [
  {
    id: 0,
    label: "currentPassword",
    name: "currentPassword",
    placeholder: "settingsPassword",
  },
  {
    id: 1,
    label: "newPassword",
    name: "newPassword",
    placeholder: "settingsNewPassword",
  },
  {
    id: 2,
    label: "confirmPassword",
    name: "confirmedPassword",
    placeholder: "settingsConfirmNewPassword",
  },
];
