export default {
  Today: "Today",
  Tomorrow: "Tomorrow",
  Yesterday:"Yesterday",
  action: "Action",
  addressBook: "Address Book",
  all: "All Orders",
  apply: "Apply",
  arrivedAtDropOff: "Arrived At Drop-off",
  arrivedAtPickUp: "Arrived At Pickup",
  awaitingSelection: "Awaiting Selection",
  cancelOrder: "Order canceled",
  cancelOrderHeader: "Are you sure you want to cancel order?",
  cancelOrderSubHeader: "You will not be able to recover afterwards",
  cancelled: "Cancelled",
  chat: "Chat",
  collected: "Collected",
  contactDriver: "Contact driver",
  contactReceiver: "Contact receiver",
  create: "Create",
  createExpedited: "Create Expedited Order",
  createRegular: "Create Order",
  createdDate: "Created Date",
  date: "Date",
  delete: "Delete",
  deleteOrderHeader: "Are you sure you want to delete draft?",
  deleteOrderSnackBar: "Order Deleted",
  deleteOrderSubHeader: "You will not be able to recover afterwards ",
  delivered: "Delivered",
  draft: "Draft",
  dropOffColumnName: "Drop-off D/T",
  edit: "Edit",
  expedited: "Expedited",
  expeditedOrder: "Expedited Order",
  failed: "Failed",
  filter: "Filter",
  goToLocations: "Go to Locations",
  helpCenter: "Help Center",
  home: "Home",
  import: "Import",
  incompleteData: "Incomplete Data",
  locationMissing: "Location Missing!",
  locationMissingSubHeader:
    "Please create your first location to start creating your orders.",
  loginExpiredMainText: "Your session has expired",
  loginExpiredSubText: "You will be directed to the login page",
  logout: "Logout",
  myContacts: "My Contacts",
  myProfile: "My Profile",
  no: "No",
  noOrdersYet: "No Orders Yet!",
  noOrdersYetSubText:
    "Start and place an order by “Import” or “Create” a new order.",
  noResultFound: "No Results Found!",
  noResultFoundSubText:
    "No results match the filters selected. Please remove filter or “Clear Filters” to show results.",
  notifications: "Notifications",
  ok: "OK",
  onHold: "On Hold",
  orderEditedSuccessfully: "Order Edited Successfully",
  orderId: "Order ID",
  orderPlacedSuccessfully: "Order Placed Successfully",
  orderStatus: "Order Status",
  orderType: "Order Type",
  ordersManagement: "Order Managment",
  ordersTracking: "Orders Tracking",
  outForDelivery: "Out Of Delivery",
  pendingPickup: "Pending Pickup",
  phoneNo: "Phone No.",
  pickUpColumnName: "Pickup D/T",
  pickUpStarted: "Pickup Started",
  publish: "Publish",
  readyForDelivery: "Ready For Delivery",
  receiverName: "Receiver Name",
  regular: "Regular",
  regularOrder: "Regular Order",
  rejected: "Rejected",
  rescheduled: "Rescheduled",
  resetFilters: "Reset Filters",
  returned: "Returned",
  searchPlaceholder: "Search by Name , phone no. & order ID",
  settings: "Settings",
  status: "Status",
  support: "Support",
  tableNavigationMessageFirstPart: "Showing 1 -",
  tableNavigationMessageSecondPart: "out of ",
  tableNavigationMessageThirdPart: " Order",
  view: "View",
  viewProfile: "View Profile",
  welcomeBack: "Welcome back",
  yes: "Yes",
};
