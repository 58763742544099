import { jwtDecode } from "jwt-decode";

const tokenKey = "token";
const refreshTokenKey = "refreshToken";

export const TokenUtils = {
  /**
   * Decode JWT token.
   *
   * @param  {string} token Token to decode.
   *
   * @return {Object} Decoded token object.
   */
  decode: (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  /**
   * Get JWT token from local storage.
   *
   * @return {Object} An object containing the access token and refresh token.
   */
  get: () => ({
    refreshToken: localStorage.getItem(refreshTokenKey) || null,
    token: localStorage.getItem(tokenKey) || null,
  }),
  /**
   * Get JWT token decoded from local storage.
   *
   * @param  {string} isAccessToken Token type to decode.
   *
   * @return {Object} The decoded token.
   */
  getDecoded: (isAccessToken = true) => {
    const { token, refreshToken } = TokenUtils.get();
    if (isAccessToken) return token ? TokenUtils.decode(token) : null;
    return refreshToken ? TokenUtils.decode(refreshToken) : null;
  },
  /**
   * Check if token is expired.
   *
   * @param  {string} isAccessToken Token type to decode.
   */
  isTokenExpired: (isAccessToken = true) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const decodedToken = TokenUtils.getDecoded(isAccessToken);
    return decodedToken && decodedToken.exp < currentTime;
  },
  /**
   * Remove access token and refresh token from local storage.
   */
  remove: () => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(refreshTokenKey);
  },
  /**
   * Set JWT access token and refresh token in local storage.
   *
   * @param {string} token Token to set.
   *
   * @param {string} refreshToken Refresh token to set if exits.
   */
  set: (token, isAccessToken = true) => {
    localStorage.setItem(isAccessToken ? tokenKey : refreshTokenKey, token);
  },
};
