import config from "../config";
import httpService from "./http-service";

export default class BaseService {
  /**
   * Perform get.
   *
   * @param  {Object} path    Request path.
   * @param  {Object} query   Request query.
   * @param  {Object} options Request options.
   *
   * @return {Promise}     Promise resolved with response data.
   */

  get = async (path, query = "", options = {}) =>
    httpService
      .get(`${config?.apiBaseUrl}${path}${query}`, options)
      .then((res) => res?.data);

  /**
   * Perform post operation for resource.
   *
   * @param  {Object} path  Request path.
   * @param  {Object} body  Request body.
   * @param  {Object} query Request query.
   *
   * @return {Promise}     Promise resolved with response data.
   */
  post = async (path, body, query = "") =>
    httpService
      .post(`${config?.apiBaseUrl}${path}${query}`, body)
      .then((res) => res?.data);

  /**
   * Perform put operation for resource.
   *
   * @param  {Object} path  Request path.
   * @param  {Object} body  Request body.
   * @param  {Object} query Request query.
   *
   * @return {Promise}     Promise resolved with response data.
   */
  put = async (path, body, query = "") =>
    httpService
      .put(`${config?.apiBaseUrl}${path}${query}`, body)
      .then((res) => res?.data);

  /**
   * Perform delete operation for resource.
   *
   * @param  {Object} path  Request path.
   * @param  {Object} query Request query.
   * 
   * @return {Promise}      Promise resolved with response data.
   */
  delete = async (path, query = "") =>
    httpService
      .delete(`${config?.apiBaseUrl}${path}${query}`)
      .then((res) => res?.data);

  /**
   * Perform patch operation for resource.
   *
   * @param  {Object} path  Request path.
   * @param  {Object} body  Request body.
   * @param  {Object} query Request query.
   *
   * @return {Promise}     Promise resolved with response data.
   */
  patch = async (path, body = {}, query = "") =>
    httpService
      .patch(`${config?.apiBaseUrl}${path}${query}`, body)
      .then((res) => res?.data);
}
