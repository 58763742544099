import { atom } from "jotai";

export const initialRegisterUserInfo = Object.freeze({
  agreeToTerms: false,
  businessAddress: "",
  businessName: "",
  businessNationalNumber: "",
  businessPhoneNumber: "",
  businessType: "",
  confirmPassword: "",
  contactPersonName: "",
  contactPhoneNumber: "",
  email: "",
  occupation: "",
  password: "",
  socialMedia: "",
});

export const registeredUserAtom = atom({
  ...initialRegisterUserInfo,
});

export const isFirstStepCompleteAtom = atom(false);

export const isRegistrationCompleteAtom = atom(false);
