import { atom } from "jotai";

export const orderStatusAtom = atom(0);

export const isExpeditedAtom = atom(null);

export const dateValueAtom = atom(undefined);

export const shipmentScheduleInputAtom = atom({
  deliveryDate: null,
  pickupDate: null,
  pickupTime: [],
  preferredDropOffTime: [],
});

export const phoneCountryCodeFieldsAtom = atom({
  contactPhoneNumber: "962",
  receiverPhoneNumber: "962",
  secondPhoneNumber: "962",
});

export const receiverIdAtom = atom("");

export const contactsOptionsAtom = atom([]);
