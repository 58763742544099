export const orderTrackingInfo = [
  {
    id: 0,
    label: "receiver",
  },
  {
    id: 1,
    label: "pickupLocation",
  },
  {
    id: 2,
    label: "pickupTime",
  },
  {
    id: 3,
    label: "dropOffLocation",
  },
  {
    id: 4,
    label: "deliveryTime",
  },
];
