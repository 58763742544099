import { BUSINESS_TYPES } from "./business-setup";

export const MY_PROFILE_SECTIONS_INFO = (t) => [
  {
    fields: [
      {
        id: 0,
        isDisabled: true,
        label: "businessName",
        name: "businessName",
        placeholder: t("businessName"),
      },
      {
        id: 5,
        label: "businessAddress",
        name: "address",
        placeholder: t("businessAddress"),
      },
      {
        id: 1,
        isDisabled: true,
        label: "loginEmail",
        name: "businessEmail",
        placeholder: t("loginEmail"),
      },
      {
        id: 2,
        isDisabled: true,
        label: "businessPhoneNumber",
        name: "businessPhoneNumber",
        placeholder: t("businessPhoneNumber"),
        type: "tel",
      },
      {
        id: 4,
        isSearchable: true,
        label: "businessType",
        name: "businessType",
        options: BUSINESS_TYPES.filter(({ id }) => id !== 0),
        type: "select",
      },
      {
        id: 3,
        label: "nationalNumber",
        name: "businessNationalNumber",
        placeholder: t("nationalNumber"),
        type: "number",
      },
    ],
    header: t("businessDetails"),
    subHeader: t("businessDetailsSubHeader"),
  },
  {
    fields: [
      {
        id: 6,
        label: "websiteURL",
        name: "website",
        placeholder: t("inputURL"),
      },
    ],
    header: t("websites"),
    subHeader: t("websitesSubHeader"),
  },
  {
    fields: [
      {
        id: 7,
        label: "opens",
        name: "openHours",
        placeholder: t("typeHour"),
        subName: "start", // in other fields we use the name as the input name, but here we use this cuz both fields have the same name (openHours)
        type: "time",
      },
      {
        id: 8,
        label: "closes",
        name: "openHours",
        placeholder: t("typeHour"),
        subName: "end", // in other fields we use the name as the input name, but here we use this cuz both fields have the same name (openHours)
        type: "time",
      },
    ],
    header: t("businessHours"),
    subHeader: t("businessHoursSubHeader"),
  },
  {
    fields: [{ id: 9, name: "files" }],
    header: t("businessDocuments"),
    subHeader: t("businessDocumentsSubHeader"),
  },
];

export const DOCUMENTS_ENUM = Object.freeze({
  1: "profilePicture",
  10: "vatNumber",
  2: "identificationImageFront",
  3: "identificationImageBack",
  4: "driverLicence",
  5: "nonCriminalRecord",
  6: "tradeLicense",
  7: "registrationCertificate",
  8: "iconImage",
  9: "logoImage",
});

export const MIME_TYPES = Object.freeze({
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  pdf: "application/pdf",
  png: "image/png",
});
