const PROD = {
  apiBaseUrl: process.env.REACT_APP_USER_MANAGEMENT_SERVICE_URL,
  reactApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};

const STAGING = {
  apiBaseUrl: process.env.REACT_APP_USER_MANAGEMENT_SERVICE_URL,
  reactApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};

const DEV = {
  apiBaseUrl: process.env.REACT_APP_USER_MANAGEMENT_SERVICE_URL,
  reactApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};

const config =
  process.env.REACT_APP_ENV === "prod"
    ? PROD
    : process.env.REACT_APP_ENV === "staging"
    ? STAGING
    : DEV;

export default config;
