export const ENGLISH_LANG = "en";
export const ARABIC_LANG = "ar";

export const languages = ["en", "ar"];
export const lang = ENGLISH_LANG;

export const languageMap = Object.freeze({
  ar: "arabic",
  en: "english",
});
