export default {
  businessDetails: "تفاصيل العمل",
  businessDetailsSubHeader: "هذه هي تفاصيل عملك بمجرد إنشاء ملفك الشخصي.",
  businessDocuments: "مستندات العمل",
  businessDocumentsSubHeader: "هذه هي المستندات التي قمت بتحميلها لعملك.",
  businessHours: "ساعات العمل",
  businessHoursSubHeader: "هذه هي ساعات العمل الخاصة بشركتك.",
  businessProfile: "ملف العمل",
  businessProfileSubHeader: "قم بتحديث تفاصيل عملك هنا.",
  closes: "ساعة الإغلاق",
  enterValidTime: "الرجاء إدخال وقت صحيح",
  enterValidURL: "الرجاء إدخال عنوان صحيح",
  inputURL: "أدخل الموقع الإلكتروني",
  nationalNumber: "الرقم الوطني للأعمال",
  opens: "ساعة الافتتاح",
  saveDataFail: "حدث خطأ أثناء حفظ التغييرات، يرجى المحاولة مرة أخرى",
  saveDataSuccess: "تم حفظ التغييرات بنجاح",
  typeHour: "أدخل الساعة",
  validationError: "بعض الحقول فارغة أو غير صحيحة",
  websiteURL: "الموقع الإلكتروني",
  websites: "المواقع الإلكترونية",
  websitesSubHeader:
    "هذا هو موقع الويب الخاص بشركتك وحساباتك على وسائل التواصل الاجتماعي.",
};
