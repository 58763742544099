import React, { lazy } from "react";

const routes = [
  {
    component: lazy(() => import("pages/login")),
    label: "Auth Login",
    path: "/",
    titleKey: "login",
  },
  {
    component: lazy(() => import("pages/registration")),
    label: "Register",
    path: "/register",
    titleKey: "Register",
  },
  {
    component: lazy(() => import("pages/business-setup")),
    label: "Business-Setup",
    path: "/setup",
    titleKey: "Business Setup",
  },
  {
    component: lazy(() => import("pages/verification")),
    label: "Verification",
    path: "/verify",
    titleKey: "Verification",
  },
  {
    children: [
      {
        component: lazy(() => import("pages/home-page")),
        label: "home",
        path: "",
      },
      {
        component: lazy(() => import("pages/order-management")),
        label: "order-management",
        path: "order-management",
      },
      {
        component: () => <div>Orders tracking page</div>,
        label: "orders-tracking",
        path: "orders-tracking",
      },
      {
        component: lazy(() => import("pages/orders-creation")),
        label: "manage-orders",
        path: "order-management/manage-orders/:orderType",
      },
      {
        component: lazy(() => import("pages/track-order")),
        label: "manage-orders",
        path: "order-management/track-order/:orderId",
      },
      {
        component: lazy(() => import("pages/my-contacts")),
        label: "My-Contacts",
        path: "my-contacts",
      },
      {
        component: lazy(() => import("pages/address-book")),
        label: "address-book",
        path: "address-book",
      },
      {
        component: lazy(() => import("pages/manage-locations")),
        label: "manage-locations",
        path: "manage-locations/:manageType",
      },
      {
        component: lazy(() => import("pages/my-locations")),
        label: "my-locations",
        path: "my-locations",
      },
      {
        component: lazy(() => import("pages/my-profile")),
        label: "my-profile",
        path: "my-profile",
      },
      {
        component: lazy(() => import("pages/settings-page")),
        label: "settings",
        path: "settings",
      },
      {
        component: () => <p>Support page</p>,
        label: "support",
        path: "support",
      },
    ],
    component: lazy(() => import("pages/dashboard")),
    label: "home",
    path: "/home",
    titleKey: "home",
  },
];

export default routes;
