export default {
  activate: "Activate",
  active: "Active",
  add: "Add",
  addContact: "Add Contact",
  address: "Address",
  allOptions: "All",
  businessPhone: "Business Phone No.",
  cancel: "Cancel",
  contactActivated: "Contact Activated",
  contactAddedSuccessfully: "Contact Added Successfully",
  contactDeactivated: "Contact Deactivated",
  contactSearchPlaceholder: "Search by Name , phone no.",
  contactUpdatedSuccessfully: "Contact Updated Successfully",
  deactivate: "Deactivate",
  deleteContactHeader: "Are you sure you want to deactivate contact?",
  deleteContactSubHeader: "You can reactivate whenever you want",
  editContact: "Edit Contact",
  inactive: "Inactive",
  mobileNumber: "Contact Mobile Number",
  name: "Name",
  nameIsRequired: "Name is required",
  newContact: "New Contact",
  secondPhone: "2nd Phone No.",
};
