export default {
  addLocation: "أضف موقع",
  area: "منطقة",
  areaIsRequired: "المنطقة مطلوبة",
  buildingNumber: "رقم المبنى",
  buildingNumberIsRequired: "رقم المبنى مطلوب",
  businessContactPerson: "جهة الاتصال التجارية",
  chooseContact: "اختيار جهة الاتصال",
  city: "مدينة",
  cityIsRequired: "المدينة مطلوبة",
  deleteLocationSecondWarning: ".لن تتمكن من استعادته بعد ذلك",
  deleteLocationWarning: "هل أنت متأكد أنك تريد حذف الموقع؟",
  floorNumber: "رقم الطابق",
  floorNumberIsRequired: "رقم الطابق مطلوب",
  location: "الموقع",
  locationCreatedSuccessfully: "تم إنشاء الموقع بنجاح",
  locationDeletedSuccessfully: "تم حذف الموقع بنجاح",
  locationIsRequired: "الموقع مطلوب",
  locationTitle: "عنوان الموقع",
  locationTitleIsRequired: "عنوان الموقع مطلوب",
  locationUpdatedSuccessfully: "تم تحديث الموقع بنجاح",
  myLocations: "مواقعي",
  myLocationsSubTitle: "انقر على الخريطة لتحديد موقعك ثم املأ التفاصيل الأخرى",
  noLocationsMessageHeader: "! لا توجد مواقع محفوظة",
  noLocationsSubMessageHeader:
    ".'قم بإنشاء موقع جديد من خلال النقر على زر 'إضافة موقع",
  officeNumber: "رقم المكتب",
  officeNumberIsRequired: "رقم المكتب مطلوب",
  primaryContactNumber: "رقم الاتصال الرئيسي",
  primaryContactNumberIsRequired: "رقم الاتصال الرئيسي مطلوب",
  primaryContactNumberPlaceholder: "اكتب رقم الاتصال",
  search: "بحث",
  streetName: "اسم الشارع",
  streetNameIsRequired: "اسم الشارع مطلوب",
  typeNumber: "اكتب الرقم",
  update: "تحديث",
};
