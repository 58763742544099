export default {
  applicationUnderReview: "طلبك قيد المراجعة",
  applicationUnderReviewFirstMessage: "!شكرًا لتقديمك",
  applicationUnderReviewSecondMessage: ".نحن نقوم حاليًا بمراجعة طلبك",
  businessAddress: "عنوان العمل",
  businessAddressIsRequired: "عنوان العمل مطلوب",
  businessInfoFirstStep: ".الخطوة ١: معلومات العمل",
  businessNationalNumber: "الرقم الوطني للأعمال (اختياري)",
  businessSecondStep: "الخطوة 2: تفاصيل الاتصال",
  businessThirdStep: "الخطوة 3: تحميل المستندات",
  contactPersonNameIsRequired: "اسم المستخدم مطلوب",
  iconHelperText:
    "اختر رمزًا بسيطًا لتمثيل نشاطك التجاري في التطبيق! يجب أن يكون هذا مختلفًا عن شعارك الرئيسي وسهل التعرف عليه. سيظهر هذا الرمز في تطبيق السائق.",
  iconImage: "صورة الأيقونة",
  iconMaxSize: "الحجم: 233 كيلوبايت كحد أقصى (512 * 512 بكسل)",
  logoImage: "صورة الشعار",
  occupation: "المهنة",
  occupationIsRequired: "الوظيفة مطلوبة",
  preview: "معاينة",
  registrationCertificate: "شهادة التسجيل التجاري",
  replace: "استبدال",
  required: "مطلوب",
  sizeLimitMessage: "تجاوز إجمالي حجم الملفات الحد الأقصى المسموح به",
  socialMedia: "الموقع الإلكتروني / وسائل التواصل الاجتماعي",
  stepsIndicatorTitle: "أكمل ملفك التجاري",
  submit: "إرسال",
  tradeLicense: "الرخصة التجارية",
  unsupportedFileFormat: "تنسيق الملف غير مدعوم",
  upload: "تحميل",
  uploadFollowingDocuments: "يرجى تحميل المستندات التالية",
  vatNumber: "رقم ضريبة القيمة المضافة",
};
