export default {
  clipboardCopyMessage: "تم نسخ الرقم إلى الحافظة",
  deliveryTime: "موعد التسليم",
  driver: "السائق",
  hideHistory: "إخفاء السجل",
  receiver: "اسم المتلقي",
  reportProblem: "الابلاغ عن مشكلة",
  showHistory: "عرض السجل",
  singleOrderTracking: "متابعة الطلب",
  trackOrder: "تتبع الطلب",
};
