import { AppError } from "./index";

/**
 * Custom error to encapsulate HTTP unauthorized error.
 */
export class ConflictError extends AppError {
  /**
   * Conflict request error class constructor.
   *
   * @param {string} errorKey      A unique key that identifies the error.
   * @param {string} errorMessage  Description of the error.
   * @param {Error}  originalError Axios response error object.
   * @param {number} statusCode
   */
  constructor(errorKey, errorMessage, originalError, statusCode) {
    super(originalError);

    this.errorMessage = errorMessage;
    this.errorKey = errorKey;
    this.statusCode = statusCode;
  }
}
