import bell from "assets/bell.svg";
import chat from "assets/chat.svg";
import dashboardUnselected from "assets/dashboard-icon-unselected.svg";
import dropDowHelpCenter from "assets/dropdown-help-center.svg";
import dropDownLocation from "assets/dropdown-location.svg";
import dropDownProfile from "assets/dropdown-profile.svg";
import orderManagementUnselected from "assets/orders-management-unselected.svg";
import orderTrackingUnselected from "assets/orders-tracking-unselected.svg";

export const NAV_LINKS = (t) => [
  {
    id: 0,
    label: t("myContacts"),
    link: "./my-contacts",
  },
  {
    id: 1,
    label: t("addressBook"),
    link: "./address-book",
  },
  {
    id: 2,
    label: t("support"),
    link: "./support",
  },
];

export const MOBILE_VIEW_NAV_LINKS = (t) => [
  {
    icon: chat,
    id: 0,
    label: t("chat"),
    link: "",
  },
  {
    icon: bell,
    id: 1,
    label: t("notifications"),
    link: "",
  },
  {
    icon: dashboardUnselected,
    id: 3,
    label: t("home"),
    link: "/",
  },
  {
    icon: orderManagementUnselected,
    id: 4,
    label: t("ordersManagement"),
    link: "./order-management",
  },
  {
    icon: orderTrackingUnselected,
    id: 5,
    label: t("ordersTracking"),
    link: "./orders-tracking",
  },
  {
    id: 6,
    label: t("myContacts"),
    link: "./my-contacts",
  },
  {
    id: 6,
    label: t("myLocations"),
    link: "./my-locations",
  },
];

export const DROP_DOWN_MENU_NAV_LINKS = (t) => [
  {
    icon: dropDownProfile,
    id: 0,
    label: t("myProfile"),
    link: "./my-profile",
  },
  {
    icon: dropDownLocation,
    id: 1,
    label: t("myLocations"),
    link: "./my-locations",
  },
  {
    icon: dropDowHelpCenter,
    id: 2,
    label: t("helpCenter"),
    link: "",
  },
];
