export default {
  accountSettings: "إعدادات الحساب",
  changePassword: "تغيير كلمة المرو",
  chooseLanguage: "اختر اللغة",
  currentPassword: "كلمة المرور الحالية",
  generalSettings: "الإعدادات العامة",
  languagePreferences: "اللغة المفضلة",
  newPassword: "كلمة المرور الجديدة",
  settingsConfirmNewPassword: "تأكيد كلمة المرور الجديدة",
  settingsNewPassword: "ادخل كلمة المرور الجديدة",
  settingsPassword: "ادخل كلمة المرور",
  updatePassword: "تحديث كلمة المرور الخاصة بك",
  userManagementSettings: "إدارة المستخدمين",
};
