import { ARABIC_LANG, ENGLISH_LANG } from "./languages";

export const LANGUAGES_OPTIONS = [
  {
    id: 0,
    label: "English",
    value: ENGLISH_LANG,
  },
  {
    id: 1,
    label: "العربية",
    value: ARABIC_LANG,
  },
];
