import googleIcon from "assets/google.png";
import outlookIcon from "assets/outlook.png";
import facebookIcon from "assets/facebook.png";
import instagramIcon from "assets/instagram.png";

export const LOGIN_FORM_INPUTS = [
  {
    id: 0,
    key: "loginEmail",
    placeholder: "loginEmailPlaceholder",
    type: "text",
  },
  {
    id: 1,
    key: "password",
    placeholder: "********",
    type: "password",
  },
];

export const ICONS = [
  {
    alt: "google",
    id: 0,
    img: googleIcon,
  },
  {
    alt: "outlook",
    id: 1,
    img: outlookIcon,
  },
  {
    alt: "facebook",
    id: 2,
    img: facebookIcon,
  },
  {
    alt: "instagram",
    id: 3,
    img: instagramIcon,
  },
];
