import * as Yup from "yup";

export const ValidationUtils = {
  form: async (values, schema) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return { isValid: true };
    } catch (err) {
      let errMessages = {};
      if (err instanceof Yup.ValidationError) {
        err.inner?.forEach((error) => {
          const path = error?.path;
          // to handle nested object validation type
          if (path.includes(".")) {
            const splitValue = path.split(".");
            const secondPart = splitValue[1];
            errMessages[secondPart] = error?.message;
          } else {
            errMessages[error?.path] = error?.message;
          }
        });
      }
      return { errMessages, isValid: false };
    }
  },
  phoneNumber: async (value) => {
    let phoneNumber = value?.includes("_") ? value?.split("_")[1] : value;
    return /^[0-9]+$/.test(phoneNumber) && phoneNumber?.length >= 6;
  },
};
