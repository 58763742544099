export const TimeUtils = {
  convertToHumanReadableFormat: (unformattedTime) => {
    const date = new Date(unformattedTime);
    const options = {
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    };
    const timeString = date.toLocaleString("en-US", options);
    return timeString;
  },
  generateTimeOptions: (length, interval) => {
    let timeOptions = [];

    for (let i = 0; i < length; i++) {
      const start = 8 + i * interval;
      const timeInterval = {
        end: start + interval,
        start,
      };

      timeOptions.push(timeInterval);
    }

    return timeOptions;
  },
};
