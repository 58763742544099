import { useSearchParams } from "react-router-dom";

export const useFilterQueryParams = () => {
  let [filterParams, setFilterParams] = useSearchParams();

  const updateFilterParam = (key, value) => {
    const newParams = new URLSearchParams(filterParams);
    newParams.set(key, value);
    setFilterParams(newParams);
  };

  const getFilterParam = (key) => filterParams.get(key);

  return { getFilterParam, updateFilterParam };
};
