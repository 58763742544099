import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "translations";

import {ARABIC_LANG,easternNumerals,ENGLISH_LANG,westernNumerals} from "constants/index";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: ENGLISH_LANG,
    interpolation: {
      escapeValue: false, // React already safes from xss
      format: (value, format, lng) => {
        if (lng === ARABIC_LANG && format === "year") {
          let formattedValue = value.toString();
          for (let i = 0; i < westernNumerals.length; i++) {
            const regex = new RegExp(westernNumerals[i], "g");
            formattedValue = formattedValue.replace(regex, easternNumerals[i]);
          }
          return formattedValue;
        }
        return value;
      },
    },
    lng: localStorage.getItem("lang"),
    resources: translations,
  });
export default i18n;
