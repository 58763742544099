import BaseService from "./base-service";

/**
 * LocationService handles location management operations.
 *
 * @class LocationService
 * @extends {BaseService}
 */
export default class LocationService extends BaseService {
  path = "location-mgmt/sender";

  /**
   * Fetches all locations.
   *
   * @returns {Promise}
   */
  getLocations = async () => await this.get(`${this.path}/getlocations`);

  /**
   * Fetches a specific location by its ID.
   *
   * @param {string} locationId The ID of the location.
   *
   * @returns {Promise}
   */
  getLocation = async (locationId) =>
    await this.get(`${this.path}/getlocation`, `?locationId=${locationId}`);

  /**
   * Creates a new location.
   *
   * @param {Object} body The location data.
   *
   * @returns {Promise}
   */
  createLocation = async (body) =>
    await this.post(`${this.path}/createlocation`, body);

  /**
   * Updates an existing location.
   *
   * @param {string} locationId The ID of the location.
   * @param {Object} body       The updated location data.
   *
   * @returns {Promise}
   */
  updateLocation = async (locationId, body) =>
    await this.put(
      `${this.path}/updatelocation`,
      body,
      `?locationId=${locationId}`
    );

  /**
   * Deletes a specific location.
   *
   * @param {string} locationId The ID of the location.
   *
   * @returns {Promise}
   */
  deleteLocation = async (locationId) =>
    await this.delete(
      `${this.path}/deletelocation`,
      `?locationId=${locationId}`
    );
  /**
   * Fetches all active pick-up locations.
   *
   * @returns {Promise}
   */
  getPickUpLocations = async () =>
    await this.get(`${this.path}/getlocations`, "?isActive=true");

  /**
   * Fetches drop-off locations for a specific receiver by their ID.
   *
   * @param {string} receiverId  The ID of the receiver.
   *
   * @returns {Promise}
   */
  getDropOffLocation = async (receiverId) =>
    await this.get(
      `${this.path}/getreciverlocations`,
      `?reciverId=${receiverId}`
    );
}
