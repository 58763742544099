import { useAtomValue, useSetAtom } from "jotai";
import {
  businessSetupStepAtom,
  isBusinessSetupCompleteAtom,
  userStatusAtom,
} from "atoms";

export const useRedirectRoutes = (path) => {
  const userStatus = useAtomValue(userStatusAtom);
  const setIsBusinessSetupComplete = useSetAtom(isBusinessSetupCompleteAtom);
  const setBusinessSetupStep = useSetAtom(businessSetupStepAtom);
  let redirectRoute = "";
  switch (path) {
    case "/":
    case "/register":
      if (userStatus) {
        if (userStatus === "PendingForApproval") {
          setIsBusinessSetupComplete(true);
          redirectRoute = "/setup";
        } else if (userStatus === "Pending") {
          setBusinessSetupStep(0);
          setIsBusinessSetupComplete(false);
          redirectRoute = "/setup";
        } else if (userStatus === "Registered") {
          redirectRoute = "/verify";
        } else {
          redirectRoute = "/home";
        }
      }
      break;
    case "/setup":
      if (!userStatus) redirectRoute = "/";
      else if (userStatus === "Registered") redirectRoute = "/verify";
      else if (userStatus === "PendingForApproval") {
        setIsBusinessSetupComplete(true);
      } else if (userStatus === "Active") {
        redirectRoute = "/home";
      }
      break;
    case "/verify":
      if (userStatus !== "Registered") redirectRoute = "/";
      break;
    case "/home":
      if (userStatus !== "Active") redirectRoute = "/";
      break;
    default:
      redirectRoute = "";
      break;
  }

  return redirectRoute;
};
