import { MIME_TYPES } from "constants";

/**
 * Generate blob url from stream that can be used as an img src or anchor tag href
 *
 * @param {object} blobStream File blob stream
 * @param {string} extention  File type extension (ex: jpeg, pdf..etc)
 *
 * @returns {object} Url and revokeURL function
 */
export const generateBlobURL = (blobStream, extention = "jpeg") => {
  if (blobStream) {
    const blob = new Blob([blobStream], {
      type: MIME_TYPES[extention?.toLowerCase()],
    });
    const url = URL.createObjectURL(blob);
    return { revokeURL: () => URL.revokeObjectURL(url), url };
  }
  return {};
};
