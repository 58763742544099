export default {
  clipboardCopyMessage: "Number copied to clipboard",
  deliveryTime: "Delivery Time",
  driver: "Driver",
  hideHistory: "Hide History",
  receiver: "Receiver",
  reportProblem: "Report A Problem",
  showHistory: "Show History",
  singleOrderTracking: "Order Tracking",
  trackOrder: "Track Order",
};
