import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const DateUtils = {
  convertToHumanReadableFormat: (date, format = "MMM D, YYYY") =>
    dayjs(date).format(format),
  convertToISOFormat: (date) =>
    dayjs(date, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DDTHH:mm:ss[Z]"),
  formatDate: (date, hour) =>
    dayjs(date)
      .hour(hour)
      .minute(0)
      .second(0)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]"),
  getLocalHour: (slot) => dayjs.utc(slot).local().hour(),
};
